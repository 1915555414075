import React from 'react'
// Components
import { Seo } from '../components'
// Styles
import { Container, Wrapper } from '../styles/common'

const DrugTestingPage = () => {
  return (
    <>
      <Seo title="drug-testing" />
      <Wrapper>
        <Container style={{ flex: 1 }}>
          <object
            data="https://firebasestorage.googleapis.com/v0/b/q-innovation-prod.appspot.com/o/legal%2FDrugTestingStatement.pdf?alt=media&token=69824b17-10d0-4ee0-9788-539830ede20b"
            type="application/pdf"
            style={{ flex: 1 }}
          />
        </Container>
      </Wrapper>
    </>
  )
}

export default DrugTestingPage
